var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "OwnerMgrForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            { attrs: { span: 24 } },
            [
              _c(
                "col2-block",
                { attrs: { title: "执行拜访任务" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "计划名称", prop: "planName" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.form.planName) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属项目", prop: "communityId" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.form.communityName) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "拜访房号", prop: "houseName" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.form.houseName) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "负责人", prop: "chargeUserName" } },
                    [
                      _vm._v(
                        "\n         " +
                          _vm._s(_vm.form.chargeUserName) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "拜访方式",
                        rules: [
                          {
                            required: true,
                            message: "请选择拜访方式",
                            trigger: "change",
                          },
                        ],
                        prop: "visitWay",
                      },
                    },
                    [
                      !_vm.isLook
                        ? _c("v-select", {
                            attrs: { options: _vm.form.visitWayList },
                            model: {
                              value: _vm.form.visitWay,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "visitWay", $$v)
                              },
                              expression: "form.visitWay",
                            },
                          })
                        : _c("v-select", {
                            attrs: {
                              disabled: "",
                              options: _vm.form.visitWayList,
                            },
                            model: {
                              value: _vm.form.visitWay,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "visitWay", $$v)
                              },
                              expression: "form.visitWay",
                            },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "被拜访人",
                        rules: [
                          {
                            required: true,
                            message: "请选择拜访人",
                            trigger: "change",
                          },
                        ],
                        prop: "visitUserType",
                      },
                    },
                    [
                      !_vm.isLook
                        ? _c("v-select", {
                            attrs: { options: _vm.VisitorsOps },
                            model: {
                              value: _vm.form.visitUserType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "visitUserType", $$v)
                              },
                              expression: "form.visitUserType",
                            },
                          })
                        : _c("v-select", {
                            attrs: { disabled: "", options: _vm.VisitorsOps },
                            model: {
                              value: _vm.form.visitUserType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "visitUserType", $$v)
                              },
                              expression: "form.visitUserType",
                            },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名", prop: "visitUserName" } },
                    [
                      _c("v-input", {
                        attrs: { disabled: _vm.isLook },
                        model: {
                          value: _vm.form.visitUserName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "visitUserName", $$v)
                          },
                          expression: "form.visitUserName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "拜访问题", prop: "questions" } },
                    _vm._l(_vm.form.questions, function (item, index) {
                      return _c(
                        "div",
                        { staticStyle: { "margin-top": "32px" } },
                        [
                          _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(index) +
                                "：" +
                                _vm._s(item.questionDes)
                            ),
                            item.required
                              ? _c("span", [_vm._v("  (必填)")])
                              : _vm._e(),
                          ]),
                          item.optionType === 1
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: { disabled: _vm.isLook },
                                      model: {
                                        value: item.checkedOption,
                                        callback: function ($$v) {
                                          _vm.$set(item, "checkedOption", $$v)
                                        },
                                        expression: "item.checkedOption",
                                      },
                                    },
                                    _vm._l(item.options, function (option) {
                                      return _c(
                                        "el-radio",
                                        { attrs: { label: option.optionId } },
                                        [_vm._v(_vm._s(option.optionDes))]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          item.optionType === 2
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      attrs: { disabled: _vm.isLook },
                                      model: {
                                        value: item.checkedOption,
                                        callback: function ($$v) {
                                          _vm.$set(item, "checkedOption", $$v)
                                        },
                                        expression: "item.checkedOption",
                                      },
                                    },
                                    _vm._l(item.options, function (option) {
                                      return _c(
                                        "el-checkbox",
                                        {
                                          key: option.optionId,
                                          attrs: { label: option.optionId },
                                        },
                                        [_vm._v(_vm._s(option.optionDes))]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          item.optionType === 3
                            ? _c(
                                "div",
                                [
                                  _c("v-input", {
                                    attrs: { disabled: _vm.isLook },
                                    model: {
                                      value: item.supplement,
                                      callback: function ($$v) {
                                        _vm.$set(item, "supplement", $$v)
                                      },
                                      expression: "item.supplement",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          item.optionType === 4
                            ? _c(
                                "div",
                                [
                                  _c("v-uploader2", {
                                    staticClass: "upload2",
                                    attrs: {
                                      action: _vm.uploadUrl,
                                      filePath: "item.supplement",
                                      limit: 5,
                                      beforeRemove: _vm.beforeRemove,
                                    },
                                    model: {
                                      value: item.supplementFile,
                                      callback: function ($$v) {
                                        _vm.$set(item, "supplementFile", $$v)
                                      },
                                      expression: "item.supplementFile",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }