import { generateMapByOpts, mapHelper } from 'common/utils'

// 状态
const statusOps = [
  {
    text: '全部状态',
    value: undefined
  },
  {
    text: '未拜访',
    value: 0
  },
  {
    text: '已拜访',
    value: 1
  },
  {
    text: '作废',
    value: 2
  }
]

const statusMap = generateMapByOpts(statusOps)
// 拜访类型
const visitTypeList = [
  {
    text: '全部类型',
    value: undefined
  }, {
    text: '日常拜访',
    value: 0
  }, {
    text: '节日拜访',
    value: 1
  }, {
    text: '满意度拜访',
    value: 2
  }, {
    text: '客服拜访',
    value: 3
  }
]
const visitTypeMap = generateMapByOpts(visitTypeList)
// 拜访方式
const visitWayList = [
  {
    text: '全部方式',
    value: undefined
  },
  {
    text: '电话',
    value: '1'
  }, {
    text: '线上',
    value: '2'
  }, {
    text: '线下/上门',
    value: '3'
  }, {
    text: '其他',
    value: '4'
  }
]
const visitWayMap = generateMapByOpts(visitWayList)


const VisitorsOps = [
  {
    text: '业主',
    value: 0
  }, {
    text: '亲属',
    value: 1
  }, {
    text: '租客',
    value: 2
  }
]
export {
  statusOps,
  statusMap,
  visitTypeList,
  visitTypeMap,
  visitWayList,
  visitWayMap,
  VisitorsOps
}
