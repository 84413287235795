<template>
  <div class='OwnerMgrForm-container' >
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <col2-detail :span="24">
        <col2-block title="执行拜访任务">
          <el-form-item label="计划名称" prop="planName">
            {{form.planName}}
          </el-form-item>
          <el-form-item label="所属项目" prop="communityId">
            {{form.communityName}}
          </el-form-item>
          <el-form-item label="拜访房号" prop="houseName">
            {{form.houseName}}
          </el-form-item>
          <el-form-item label="负责人" prop="chargeUserName">
           {{form.chargeUserName}}
          </el-form-item>
          <el-form-item label="拜访方式" :rules="[{ required: true, message: '请选择拜访方式', trigger: 'change' }]" prop="visitWay">
            <v-select v-if="!isLook" v-model="form.visitWay" :options="form.visitWayList"></v-select>
            <v-select v-else disabled v-model="form.visitWay" :options="form.visitWayList"></v-select>
          </el-form-item>
          <el-form-item label="被拜访人" :rules="[{ required: true, message: '请选择拜访人', trigger: 'change' }]" prop="visitUserType">
            <v-select  v-if="!isLook"  v-model="form.visitUserType" :options="VisitorsOps"></v-select>
            <v-select   v-else disabled v-model="form.visitUserType" :options="VisitorsOps"></v-select>
          </el-form-item>
          <el-form-item label="姓名" prop="visitUserName">
            <v-input  :disabled="isLook" v-model="form.visitUserName"></v-input>
          </el-form-item>
          <el-form-item label="拜访问题" prop="questions">
            <div v-for="(item,index) in form.questions" style="margin-top: 32px">
              <div>
                {{index}}：{{item.questionDes}}<span v-if="item.required">  (必填)</span>
              </div>
              <!--单选-->
              <div v-if="item.optionType === 1">
                <el-radio-group v-model="item.checkedOption" :disabled="isLook" >
                  <el-radio v-for="option in item.options" :label="option.optionId" >{{option.optionDes}}</el-radio>
                </el-radio-group>
              </div>
              <div v-if="item.optionType === 2">
                <el-checkbox-group v-model="item.checkedOption" :disabled="isLook">
                  <el-checkbox v-for="option in item.options" :label="option.optionId" :key="option.optionId">{{option.optionDes}}</el-checkbox>
                </el-checkbox-group>
              </div>
              <div v-if="item.optionType === 3">
                <v-input :disabled="isLook"  v-model="item.supplement"></v-input>
              </div>
              <div v-if="item.optionType === 4">
                <v-uploader2
                  class="upload2"
                  :action="uploadUrl"
                  filePath="item.supplement"
                  v-model="item.supplementFile"
                  :limit="5"
                  :beforeRemove="beforeRemove"
                />
              </div>
            </div>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import * as allTaskFromUrl from './api'
import * as  allVisitOwnerFromUrl from '../visitOwnerConfig/api'
import { Col2Detail, Col2Block, MultiSelect2, ChosenListPanel,TabsPanel  } from 'components/bussiness'
import { houseTypeMap, roomStatusMap, roomStatusOps, usingStatusOps, usingStatusMap } from 'views/project/projectHouseConfig/validHouse/map'
import {communityParams} from "common/select2Params";

import {vUploader2} from "components/control";
import {VisitorsOps,visitWayList} from './map'



export default {
  name: 'VisitTaskForm',
  components: {
    vUploader2,
    MultiSelect2,
    ChosenListPanel,
    TabsPanel,
    Col2Block,
    Col2Detail
  },
  props:{
    editQuery:Object
  },
  data () {
    return {
      VisitorsOps,
      visitWayList,
      isLook: false,
      uploadUrl:allTaskFromUrl.uploadURL,

      userBind: {
        searchUrl: allTaskFromUrl.getUserListURL,
        request: {
          text: 'username',
          value: 'id'
        },
        response: {
          text: 'username',
          value: 'id'
        }
      },
      maxDate: new Date(),
      usingStatusOps: usingStatusOps(1),
      houseTypeOps: [],
      roomStatusOps: roomStatusOps(1),
      submitConfig: {
        submitUrl: allTaskFromUrl.saveQueURL,
        queryUrl: '',
        submitMethod: 'post'
      },
      form:{
        chargeUserId:"7219fb2e-5ba1-4851-b737-7bd67015cc2f",
        chargeUserName:"张三",
        communityId:"feaaad4f-480a-11ef-9afe-fa163e103d5e",
        communityName:"清正源府",
        endTime:"2024-08-23 14:41:57",
        houseId:120251786,
        houseName:"清正源府->物业服务中心->1单元->101室",
        planName:"测试计划",
        questions:[
          {
            beAssociated:0,
            checked:false,
            checkedOption:'',
            isSupplement:0,
            maxSelectCnt:0,
            minSelectCnt:0,
            optionType:3,
            options:[],
            questionDes:"测试问题",
            questionId:26,
            required:1,
            supplement:""
          }
        ],
        startTime:"2024-08-23 14:41:54",
        taskId:3,
        visitPlanId:6,
        visitStatus:0,
        visitTempId:7,
        visitTime:null,
        visitUserName:"",
        visitUserType:0,
        visitWay:undefined,
        visitWays:"0,1"
      },
      visible:false,
      activeName: 'baseInfoForm',
      communityIdConfig: communityParams,
      houseSearchUrl: allTaskFromUrl.getHouseListURL,
      tableHeaders: [
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'blockName',
          label: '苑'
        },
        {
          prop: 'buildingNumber',
          label: '幢'
        },
        {
          prop: 'unit',
          label: '单元'
        },
        {
          prop: 'room',
          label: '室'
        },
        {
          prop: 'houseTypeText',
          label: '房屋类型',
          formatter (row) {
            return houseTypeMap[row.houseType]
          }
        },
        {
          prop: 'roomStatusText',
          label: '房屋状态',
          formatter: row => roomStatusMap[row.roomStatus]
        },
        {
          prop: 'statusText',
          label: '使用状态',
          formatter: row => usingStatusMap[row.status]
        }
      ],
      searchParams: {
        usingStatus: 1,
        blockName: '',
        buildingNumber: '',
        unit: '',
        room: '',
        houseType: undefined,
        roomStatus: undefined,
        communityId: undefined
      },
      responseParams: {
        id: 'id',
        name: 'text'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      visitConfig: {
        data: ''
      },
      userId: {
        id: ''
      },
      houseId: {
        id: ''
      },
      id:'',
      visitTempList:[],
      tabs: [],
      activeLabel: '基础信息',
      communityIdOps: [],
      vdomId: 1,
      query:{}
    }
  },
  computed: {
    subPageType() {
      return this.$store.state.theme['sub-page-type'] || 'inner'
    },
  },
  created () {
    this.query = this.subPageType == 'drawer' ? this.editQuery : this.$route.query
    let { id,isLook } = this.query
    this.isLook = isLook;
    if (id) {
      this.$axios({
        url: allTaskFromUrl.getTaskDetailURL+"?id="+id,
        method: 'GET',
      }).then(res => {
        var questions = [];
        for (let i = 0; i < res.data.questions.length; i++) {
          var question =  {
            beAssociated:res.data.questions[i].beAssociated,
            checked:res.data.questions[i].checked,
            checkedOption:res.data.questions[i].optionType === 1? '' : [],
            isSupplement:res.data.questions[i].isSupplement,
            maxSelectCnt:res.data.questions[i].maxSelectCnt,
            minSelectCnt:res.data.questions[i].minSelectCnt,
            optionType:res.data.questions[i].optionType,
            options:res.data.questions[i].options,
            questionDes:res.data.questions[i].questionDes,
            questionId:res.data.questions[i].questionId,
            required:res.data.questions[i].required,
            supplement:res.data.questions[i].supplement,
            supplementFile:res.data.questions[i].supplement ? [res.data.questions[i].supplement] : []
          }
          for (let j = 0; j < res.data.questions[i].options.length; j++) {
            if (res.data.questions[i].optionType === 1) {
              if (res.data.questions[i].options[j].checked) {
                question.checkedOption = res.data.questions[i].options[j].optionId
              }
            }else if (res.data.questions[i].optionType === 2) {
              if (res.data.questions[i].options[j].checked) {
                question.checkedOption.push(res.data.questions[i].options[j].optionId)
              }
            }
          }
          questions.push(question);
        }
        this.form = {
          chargeUserId:res.data.chargeUserId,
          chargeUserName:res.data.chargeUserName,
          communityId:res.data.communityId,
          communityName:res.data.communityName,
          endTime:res.data.endTime,
          houseId:res.data.houseId,
          houseName:res.data.houseName,
          planName:res.data.planName,
          questions:questions,
          startTime:res.data.startTime ,
          taskId:res.data.taskId,
          visitPlanId:res.data.visitPlanId,
          visitStatus:res.data.visitStatus ,
          visitTempId:res.data.visitTempId,
          visitTime:res.data.visitTime,
          visitUserName:res.data.visitUserName,
          visitUserType:res.data.visitUserType,
          visitWay:res.data.visitWay+"",
          visitWays:res.data.visitWays
        }
        this.form.visitWayList = [];
        this.form.visitWay = this.form.visitWay+""
        var visitWays = this.form.visitWays.split(",");
        for (let i = 0; i < visitWays.length; i++) {
          for (let j = 0; j < this.visitWayList.length; j++) {
            if (visitWays[i] == this.visitWayList[j].value) {
              this.form.visitWayList.push(this.visitWayList[j])
            }
          }
        }
        this.form.visitWay = this.form.visitWayList[0].value
      })
    }
    this.$axios({
      url: allVisitOwnerFromUrl.getListURL+"?pageSize=100",
      method: 'GET',
    }).then(res => {
      this.visitTempList = res.data.resultList
    })
  },
  methods: {
    update (data) {
      console.log(data);
      if (data.length) {
        this.form.communityId = data[0].communityId
        this.form.houseIdList = data.map(item => {
          let arr = [item.blockName, item.buildingNumber, item.unit, item.room]
          return {
            ...item,
            text: arr.filter(val => val).join('-'),
            id: String(item.houseId)
          }
        })
        this.form.userId = data[0].userId
        this.communityName = data[0].communityName
      }
    },
    async beforeRemove() {
      // 上传文件删除前
      const isOk = await this.$confirm("此操作将永久删除该文件, 是否继续？");
      return isOk;
    },
    submitBefore (data) {
      console.log(data)
      for (let i = 0; i < data.questions.length; i++) {
        if (data.questions[i].required) {
          if (data.questions[i].optionType === 1 ) {
            if (!data.questions[i].checkedOption) {
              this.$message.error(data.questions[i].questionDes+'题目请至少选择一项')
              return false
            }
            data.questions[i].options = [data.questions[i].checkedOption]
          }
          if (data.questions[i].optionType === 2) {
            if (data.questions[i].checkedOption.length < data.questions[i].minSelectCnt) {
              this.$message.error(data.questions[i].questionDes+'题目至少选择'+data.questions[i].minSelectCnt+'项')
              return false
            }
            data.questions[i].options = data.questions[i].checkedOption
          }
          if (data.questions[i].optionType === 3) {
            if (!data.questions[i].supplement) {
              this.$message.error(data.questions[i].questionDes+'题目未填写答案')
              return false
            }
          }
          if (data.questions[i].optionType === 4) {
            if (data.questions[i].supplementFile.length === 0) {
              this.$message.error(data.questions[i].questionDes+'文件未上传')
              return false
            }
            data.questions[i].supplement = JSON.stringify(data.questions[i].supplementFile)
          }
        }

      }
      data.visitWay = parseInt(data.visitWay);
      return true
    },
    submitSuccess (houseIds) {

        return true
    },
  }
}
</script>
